@use '@angular/material' as mat;
@include mat.core();

@import "./theme.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


* {
    @apply text-cb-text;
    font-family: 'DM Sans', sans-serif;
}

html {
    font-size: 16px;

    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
}

body {
    @apply bg-cb-background-1;
    margin: 0;
    padding: 0;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizelegibility;
}

h1 {
    font-size: 3rem;
    line-height: 3.8rem;
    font-weight: 600;
}

h2 {
    font-size: 2.6rem;
    font-weight: 600;
}

h3 {
    font-size: 1.6rem;
    font-weight: 600;
}

p {
    font-size: 1rem;
    font-weight: 400;
}

.cdk-overlay-container{
    z-index: 10000;

    .cdk-overlay-backdrop{
        background-color: rgba(0, 0, 0, 0.85);
        backdrop-filter: blur(10px);
    }
}

div.cb-page-wrapper{
    @apply flex items-stretch;
    height:100vh;
}

div.cb-page-container{
    @apply flex-1 overflow-auto;
}

div.cb-page-content{
    @apply flex flex-col;
    @apply h-full;

    div.cb-page-header{
        @apply border-b border-b-cb-divider;

        img.cb-page-topbar-logo{
            @apply h-10;
        }
    }

    div.cb-page{
        @apply flex flex-col;
        @apply flex-1 overflow-auto;
        @apply max-lg:px-4;
        max-width:100vw;

        div.cb-page-actions{
            @apply flex items-center justify-between;
            @apply p-4;

            div.cb-page-buttons{
                @apply flex items-center gap-2;
            }
        }

        div.cb-page-table{
            @apply flex-1 pt-4;
            @apply overflow-auto;

            div.p-datatable{
                @apply flex flex-col;
                @apply h-full;

                div.p-datatable-wrapper{
                    @apply flex-1;

                    table{
                        @apply relative;
                        
                        thead{
                            @apply sticky top-0;
                            z-index:400;

                            tr{
                                th{
                                    @apply bg-cb-background-1;
                                    @apply first:pl-4 last:pr-4;
                                    @apply text-sm whitespace-nowrap;

                                    &.p-frozen-column{
                                        @apply sticky;
                                    }
                                }
                            }
                        }

                        tbody{
                            z-index:300;

                            td{
                                @apply first:pl-4 last:pr-4;

                                &.p-frozen-column{
                                    @apply px-4;
                                    @apply sticky bg-cb-background-1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

div.cb-empty-page-message {
    @apply flex flex-col items-center justify-center;
    width: 100%;
    height: 100%;

    h4 {
        font-weight: 600;
        font-size: 2rem;
    }

    p {
        font-size: 1rem;
    }

    cb-button {
        @apply mt-6;
    }
}

div.cb-bottom-deco{
    @apply absolute -bottom-8;
    @apply w-full;
    @apply h-16 max-lg:hidden;
    z-index: 600;
    background-size:contain;
    background-image: url("/assets/decoration/deco-rgb.svg");
}

div.cb-dialog-entity-title{
    h4{
        @apply font-medium text-2xl;
    }

    div.cb-dialog-info{
        @apply flex items-center;

        p{
            @apply text-sm text-cb-text-light;

            &:not(:last-child){
                &::after{
                    @apply text-cb-text-light;
                    @apply mx-1;
                    content: "•";
                }
            }
        }
    }
}


div.cb-fields-customization-container{
    table.cb-fields-customization-form{
        @apply w-full;
    
        thead{
            th{
                @apply text-left p-1 text-sm;
                @apply first:pl-0;
                 
            }
        }
    
        tbody{
            &>tr{

                &>td{
                    @apply text-center p-1;
                    @apply first:pl-0;

                    &.cb-drag{
                        cb-icon{
                            @apply text-sm text-cb-text-light;
                            @apply cursor-grab active:cursor-grabbing;
                        }
                    }
    
                    &.cb-field-name{
                        @apply w-full;
                    }
    
                    &:last-child{
                        cb-icon{
                            @apply cursor-pointer text-lg;
                            @apply text-cb-danger-basic hover:text-cb-danger-hover active:text-cb-danger-active;
                        
                            &.disabled{
                                @apply text-cb-text-light hover:text-cb-text-light active:text-cb-text-light opacity-30;
                            }
                        }
                    }
                }

                &.cb-field-options-table{
                    table{
                        @apply w-full mb-3;
                        @apply border border-cb-divider;

                        tr{
                            td{
                                @apply first:w-full;
                                @apply p-1 last:pr-2;
                            }

                            &.cb-field-add-option{
                                @apply cursor-pointer select-none;

                                td{
                                    @apply text-left p-2;

                                    div.cb-field-add-button{
                                        @apply w-full;
                                        @apply flex items-center gap-2;

                                        cb-icon, p{
                                            @apply text-cb-text-light;
                                        }
                                    }
                                }

                                &:hover{
                                    td{                                    
                                        div.cb-field-add-button{
                                            cb-icon{
                                                @apply text-cb-primary-basic;
                                            }

                                            p{
                                                @apply text-cb-text;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &>cb-button{
        @apply mt-2;
    }
}

tr.cdk-drag{
    &.cdk-drag-dragging{
        td{
            @apply bg-cb-background-1;
            @apply shadow-lg;
        }
    }

    &.cdk-drag-preview{
        td{
            @apply bg-cb-background-2;

            *{
                @apply opacity-0;
            }
        }
    }
}

p.cb-empty-message{
    @apply my-4 text-lg;
    @apply text-cb-text-light;
}