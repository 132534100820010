$sidebar-width: 240px;

@keyframes placeHolderShimmer {
    0% {
        background-position: right center;
    }

    100% {
        background-position: left center;
    }
}

@mixin button-transparent-hover() {
    &:hover {
        opacity: 0.9;
    }

    &:active {
        opacity: 0.7;
    }
}

@mixin loading-background() {
    $bg-light-color-1: #EFF2F4;
    $bg-light-color-2: #ced7df;

    cursor: progress !important;
    color: transparent !important;
    animation-duration: 2s;
    border-radius: 0.4rem;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-position: left center;
    background: $bg-light-color-2;
    background: linear-gradient(to right, $bg-light-color-1 15%, $bg-light-color-2 25%, $bg-light-color-1 35%, $bg-light-color-1 65%, $bg-light-color-2 75%, $bg-light-color-1 85%);
    background-size: 1000% 100%;
    transition: 0.1s;
    -webkit-transition: 0.1s;
    position: relative;

    * {
        transition: 0.1s;
        -webkit-transition: 0.1s;
        opacity: 0;
    }

    &.void-content {
        padding: 30px 0;
        width: 100%;
    }
}